import "./App.css";
import { Routes, Route, HashRouter } from "react-router-dom";
import { LandingPage } from "./app/pages/Landing-page/Loadable";

function App() {
  return (
    <>
      <HashRouter>
        <div className="">
          <Routes>
            <Route path="/" element={<LandingPage />} />
          </Routes>
        </div>
      </HashRouter>
    </>
  );
}

export default App;
